/* General Styling */
.forum-page {
    font-family: 'Poppins', sans-serif;
}

.Auth-form-container {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.Auth-form-content {
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Poppins', sans-serif;
}

.Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: bolder;
}

/* Button Styling */
button {
    width: 150px;
}

.btn-navy {
    color: white;
    background-color: #2e8b57;
    font-family: 'Poppins', sans-serif;
}

/* Form Labels */
label {
    font-size: 20px;
    font-weight: 500;
    color: rgb(34, 34, 34);
}

/* Reset margins for Form.Label and Form.Control */
.form-label {
    margin-bottom: 0; /* Remove bottom margin */
}

.form-control {
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0; /* Remove bottom margin */
}

/* Auth Form Styling */
.Auth-form {
    width: 100vw; /* Full viewport width */
    left: 50%; /* Positioning for centering */
    margin-left: -50vw; /* Center the box */
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    background-color: white;
    overflow: hidden; /* Prevent overflowing */
    position: relative; /* Allow positioning */
}

input,
textarea {
    width: 100%; /* Full width for inputs */
    max-width: 100%; /* No overflow */
    box-sizing: border-box; /* Ensure padding fits within the width */
}

@media (max-width: 768px) {
    .Auth-form-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .form-label,
    .form-control {
        width: 100%;
    }

    .btn-navy {
        width: 100%;
    }
}

.textblackH {
    font-family: verdana, arial, sans serif;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
}

.textblack {
    font-family: verdana, arial, sans serif;
    font-size: 13px;
    color: #000000;
}

.raleway {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-size: 20px;
    font-style: normal;
  }

  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size:16px;
  }

  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 25px;
  }