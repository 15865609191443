
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica", sans-serif;
  min-height: 100%;
  overflow-x: hidden;
}

.logo {
  height: 80px; /* Set the desired height */
  padding: 0; /* Remove padding */
  margin: 0; /* Ensure no margin */
  display: block; /* Ensures it's treated as a block element */
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack logo and menu vertically on mobile */
    align-items: flex-start; /* Align items to the left */
  }

  .navbar-brand {
    width: 100%;
    text-align: left; /* Ensure logo stays on the left */
  }

  .navbar-collapse {
    width: 100%;
    padding-left: 0; /* Align menu with logo */
    margin-top: 10px; /* Add space between logo and menu */
  }

  .navbar-toggler {
    align-self: flex-end; /* Keep the toggle button aligned to the right */
  }

  .nav-link {
    padding: 0.5rem 1rem;
    font-size: 1rem; /* Ensure readability */
  }

  .border-below {
    width: 100%;
    border-top: 2px solid #ccc;
    margin-top: 5px; /* Adjust space to be closer to the navbar */
  }
}

.text-heading{
 text-decoration: none;
 color: black;
 text-align: center;
 font-weight: bold;
 font-size: 16px;
 font-family: "Helvetica", sans-serif;
}

.text-all{
 text-decoration: none;
 color: black;
 text-align: left;
 font-weight: lighter;
 font-size: 12px;
 font-family: "Helvetica", sans-serif;
}

.text-all-r{
 text-decoration: none;
 color: black;
 text-align: right;
 font-weight: lighter;
 font-size: 12px;
 font-family: "Helvetica", sans-serif;
}

.border-below {
  height: 10px;
  width: 100%;
  background-color: #333;
}

footer {
background-color: #333;
color: white;
padding: 0;
text-align: center;
font-family: "Helvetica", sans-serif;
}

.footer-content a {
  text-decoration: none;
  color: white;
  font-weight: lighter;
  font-family: "Helvetica", sans-serif;
}

.left-half {
  /*width: 50%;*/
  height: 300px;
  padding: 10px;
  justify-content: center;
  /*background-color: #e6e1f9;*/
}

.right-half {
  /*width: 50%;*/
  height: 300px;
  padding: 10px;
  background-color: #fff;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.container {
  /*max-width: 800px;*/
  margin: 0 auto;
}

.post {
  margin: 11px 0;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 13px;
  font-weight: lighter;
  font-family: "Helvetica", sans-serif;
}

.votes {
  margin-top: 10px;
  font-size: 11px;
  font-weight: lighter;
  font-family: "Helvetica", sans-serif;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  font-weight: lighter;
  font-family: "Helvetica", sans-serif;
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 10px;
  border: 1px solid #888;
  width: 40%;
  font-weight: lighter;
  font-family: "Helvetica", sans-serif;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.upvote-button, .downvote-button {
  display: inline-block;
  cursor: pointer;
  margin-right: 15px;
}

.upvote-button img, .downvote-button img {
  width: 15px;
  height: 15px;
}

.comments-container {
padding-left: 20px;
font-weight: lighter;
font-family: "Helvetica", sans-serif;
}

.comment-button {
  background-color: #145A32;
  color: white;
  padding: 3px;
  border: none;
  cursor: pointer;
}

.comment-button:hover {
  background-color: #145A32;
}

#commentInput {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
}

#addCommentButton {
  background-color: #145A32;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

#addCommentButton:hover {
  background-color: #145A32;
}

#createPostButton {
  background-color: #145A32;
  color: white;
  padding: 3px;
  border: none;
  cursor: pointer;
}

#createPostButton:hover {
  background-color: #145A32;
}

#addPostButton {
  background-color: #145A32;
  color: white;
  padding: 3px;
  border: none;
  cursor: pointer;
}

#addPostButton:hover {
  background-color: #145A32;
}

#submitButton {
  background-color: #145A32;
  color: white;
  padding: 3px;
  border: none;
  cursor: pointer;
}

#submitButton:hover {
  background-color: #145A32;
}

.header{
  padding:16px;
}

.navbar.bg-light {
  background-color: #ffffff; /* Set to pure white */
}

.carousel-container{
  height:400px;
}

.App-link {
  color: #61dafb;
}

.custom-donor {
  height: 900px;
  width: 100%;
  max-width: 500px;
  min-width: 250px;
  max-height: none !important;
}
