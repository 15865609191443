  .navbar {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #e1efec;
  z-index: 1000;
  position: relative;
  width: 100%;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.header {
  font-family: 'Poppins', sans-serif;
  background-color: #e1efec;
  font-size: 20px;
  width: 100%;
}

/*.navbar-nav>.nav-link {
  position: relative;
  text-decoration: none;
}*/

.navbar-nav>.nav-link {
  color: #006064;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  transition: color 0.3s ease;
  font-weight: bold;
}

/*.navbar-nav>.nav-link:hover {
  color: purple;
  text-decoration: none;
}*/

/*.navbar-nav>.nav-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 2px;
  left:50%;
  background-color: purple;
  transition: width 0.3s ease, left 0.3s ease;
  transform: scaleX(0);
  transform-origin: 0 0;
}*/

/*.navbar-nav>.nav-link:hover::after {
  width: 100%;
  left: 0;
}*/

.support-us-link {
  background-color: #ff6600; /* Choose your preferred background color */
  color: white;
  padding: 5px 5px;
  border-radius: 2px;
  text-align: top;
  font-weight: bold;
  text-decoration: none;
}

.support-us-link:hover {
  background-color: #0c00b3; /* Darker shade for hover effect */
  color: white;
  text-decoration: none;
}
